import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/search/:search_id',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '../views/page/Search.vue'),
    props: true
  },
  {
    path: '/content/:Content_id',
    name: 'Content',
    component: () => import(/* webpackChunkName: "content" */ '../views/page/Content.vue'),
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue')
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: () => import(/* webpackChunkName: "userProfile" */ '../views/user/UserProfile.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/page/Contacts.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/unsubscribes',
    name: 'Unsubscribes',
    component: () => import(/* webpackChunkName: "unsubscribes" */ '../views/user/Unsubscribes.vue'),
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/categories/:cat_id/:cat_name',
    name: 'Categories',
    component: () => import(/* webpackChunkName: "categories" */ '../views/category/Categories.vue'),
    props: true,
    meta: {
      IsAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*', 
    name: 'NotFounds',
    component: () => import(/* webpackChunkName: "notFounds" */ '../views/page/NotFounds.vue'),
  }
  // { path: '/:pathMatch(.*)*', name: 'NotFounds', component: NotFounds },
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  const { $cookie } = router.app.config.globalProperties;
  
  // Check if the route requires authentication
  if (to.meta.IsAuth) {
    // Check if necessary cookies are available
    if ($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
      // Verify cookie values
      const msisdn = $cookie.getCookie("msisdn");
      const status = $cookie.getCookie("status");
      
      if (msisdn && status === '1') {
        next();  // Allow navigation to the route
      } else {
        // Clear any invalid cookies
        $cookie.removeCookie("msisdn");
        $cookie.removeCookie("status");
        
        // Redirect to login page
        next(window.open("https://dsplp.sd.zain.com/?p=2741755443", "_self"));
      }
    } else {
      // Redirect to login page
      next(window.open("https://dsplp.sd.zain.com/?p=2741755443", "_self"));
    }
  } else {
    // Allow navigation to non-authenticated routes
    next();
  }
});


// router.beforeEach((to, from, next) => {
//   const { $cookie } = router.app.config.globalProperties;
  
//   // Check if the route requires authentication
//   if (to.meta.IsAuth) {
//     // Check if necessary cookies are available
//     if ($cookie.isCookieAvailable("msisdn") && $cookie.isCookieAvailable("status")) {
//       next();  // Allow navigation to the route
//     } else {
//       // Redirect to a specific URL if cookies are missing
//       // console.warn("Missing authentication cookies. Redirecting...");
//       next(window.open("https://dsplp.sd.zain.com/?p=2741755443", "_self"));
//       // next();
//     }
//   } else {
//     // Allow navigation to non-authenticated routes
//     next();
//   }
// });

export default router
